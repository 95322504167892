import { useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import style from './App.module.css';
import Typewriter from 'typewriter-effect/dist/core';

export default function App() {

  useEffect(()=>{

    const typewriter = new Typewriter('#typewriter', {
      delay: 20,
      cursor: '_'
    });
    
    typewriter
      .start()
      .typeString('<b><span class="tw-terminal-username">baadev@baadev</span>:<span class="tw-terminal-path">~</span>$</b> ')
      .pauseFor(500)
      .typeString('<b>cd about/</b>')
      .pasteString('<br>')
      .typeString('<b><span class="tw-terminal-username">baadev@baadev</span>:<span class="tw-terminal-path">~/about</span>$</b> ')
      .pauseFor(500)
      .typeString('<b>cat baadev</b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .changeDelay(10)
      .typeString('Alexander Belov')
      .pasteString('<br class="tw-terminal-sm-br">')
      .pasteString('<br>')
      .typeString('Software Developer | Web Developer | Software Development Mentor')
      .pasteString('<br>')
      .pasteString('<br>')
      .typeString('You can find me on:')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<b>> <a class="tw-terminal-link" href="https://github.com/baadev" target="_blank">GitHub</a></b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<b>> <a class="tw-terminal-link" href="https://www.linkedin.com/in/baadev/" target="_blank">LinkedIn</a></b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<b>> <a class="tw-terminal-link" href="https://habr.com/en/users/baadev/" target="_blank">Habr</a></b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<b>> <a class="tw-terminal-link" href="https://adplist.org/mentors/alexander-belov" target="_blank">ADPList</a></b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<b>> <a class="tw-terminal-link" href="https://stackoverflow.com/users/21356381/alexander-belov" target="_blank">Stack Overflow</a></b>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('<br>')
      .pasteString('For any professional inquiries, please ')
      .pasteString('<b><a class="tw-terminal-link" href="mailto:baadev15@gmail.com">email me</a></b>')
      .pasteString(' or reach me out in <b><a class="tw-terminal-link" href="https://t.me/baadev" target="_blank">Telegram</a></b>')
      .pasteString('<br>')

    }, [])

  return (
    <View style={styles.container}>
      <div id="typewriter" style={styles.tyName}></div>
      <StatusBar style="auto" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  tyName: {
    fontFamily: 'monospace',
    paddingTop: 60,
    paddingLeft: 20,
    fontSize: 18,
    color: '#fff',
  },
});
